import Vue from 'vue';
import VueRouter from 'vue-router';
import Router from 'vue-router';
import layout from '../layout';
import PageNotFound from '@/pages/PageNotFound';

// redirect: {  path: '/epic/launch/newPatient', query: { drugname: 'vancomycin', iss:'iss',launch:'launch' }}, 

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);
Vue.use(Router);

const routes = [
  {
    path: '/',
    component: layout,
    redirect: "/epic/launch/newPatient/:drugname/:iss/:launch",
    // redirect: { path: '/epic/launch/newPatient/' },
    meta: {
      requiresAuth: false,
      requiresAgreement: false,
    },
    children: [
      {
        path: '/epic/launch/newPatient',
        // redirect: "/epic/launch/newPatient/:drugname/:iss/:launch",
        name: 'NewPatient',
        component: () => import('@/pages/patient/PatientDetail'),
      },
    ],
  },

  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
